@media (min-width:0px) and (max-width: 600px) {
    .forPhone{
        display: block;
    }
    .forPC{
        display: none;
    }

    body{
        width: 100vw;
    }
    
    .navbar img{
        margin-left: 0rem;
    }

    .checkbtn{
        position: relative;
        font-size: 25px;
        top: 30px;
        right: 30px;
    }

    .navbarList{
        flex-direction: column;
        margin-right: 0rem;
        background-color: white;
    }

    .navList{
        text-align: center;
    }

    .offerImg{
        height: auto;
        width: 100%;
        margin: 2rem auto;
    }

    .homeBody{
        flex-direction: column;
        padding: 2.5rem;
    }

    .homeButton{
        font-size: 14px;
    }

    .what{
        background-size: 100% 800px;
        height: 800px;
        padding-top: 2rem;
    }

    .whatBody{
        flex-direction: column;
    }
    
    .whatIs{
        padding-left: 3rem;
        padding-right: 1rem;
        padding-top: 0rem;
    }

    .whatSo{
        width: 100vw;
        padding-top: 5rem;
        padding-left: 3rem;
    }

    .complete img{
        width: 20rem;
    }

    .used-card{
        flex-direction: column;
        font-size: 10px;
        width: 90vw;
        height: 30rem;
    }

    .used-left{
        padding: 0px 3px;
    }

    .used-head{
        font-size: 20px;
        padding: 10px;
    }

    .used-right{
        font-size: 15px;
        padding: 2rem 1rem;
    }

    .used-right li i{
        font-size: 25px;
    }

    .product-list-1 img, .product-list-2 img{
        margin: 2rem 0rem;
    }

    .product-list-text{
        width: 100%;
        padding-left: 3rem;
        padding-top: 2rem;
    }

    .demo-body{
        flex-direction: column;
    }

    .demo-body-input{
        padding: 2.5rem 0 0 0;
    }

    .demo button{
        margin-top: 15px;
    }

    .emailBody{
        flex-direction: column;
    }

    .emailBodyTop{
        width: 100%;
        padding: 0rem 0 2rem 3rem;
    }

    .message img{
        width: 20rem;
    }

    .messageCharge{
        margin-top: 2rem;
    }

    .messageChargeTablePhone{
        margin: 1.5rem 1rem 0 1rem;
    }

    .messageChargeTablePhone tbody tr td[name='country']{
        background-color: #eff0f7;
    }
    
    .messageChargeTablePhone tbody tr td[name='amount']{
        background-color: #f7f7fc;
    }
    
    .messageChargeTablePhone table .tableLeft{
        border-right: 5px solid white;
    }
    
    .messageChargeTablePhone tbody td{
        font-size: 16px;
    }

    .updated-date{
        margin: 10px 0 10px 2rem;
    }

    .updated-date img{
        margin-left: 0rem;
    }

    .experience-body{
        flex-direction: column;
        padding: 1rem;
        max-width: 22rem;
    }

    .experience-number{
        border-top: 1px solid rgba(0,0,0,.2);
        border-left: none;
        width: 100%;
    }

    .experience-radio{
        width: 100%;
        padding: 1rem;
    }

    .alwaysBody{
        flex-direction: column;
    }

    .alwaysBodyHead, .alwaysBodyMain{
        margin: 1rem auto;
    }

    .alwaysBodyCard{
        width: 100%;
    }

    .alwaysBodyCard p{
        font-size: 14px;
    }

    .alwaysBodyCard i{
        font-size: 20px;
    }

    .let h1{
        padding: 0 5rem;
        font-size: 22px;
    }

    .letButton1, .letButton2{
        font-size: 14px;
        padding: 8px;
    }

    .faq>h1{
        padding: auto;
    }

    .faqBox>div, .bulkBox>div{
        flex-direction: column;
    }

    .bulk>h1{
        padding-left: 3rem;
    }
    

}
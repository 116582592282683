/* @tailwind base;
@tailwind components;
@tailwind utilities; */

/*pricing toggle*/
@import url("https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap");

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 28px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #150937;
    -webkit-transition: 0.4s;
  
    transform: translate(0px, 0px);
    transition: 0.6s ease transform, 0.6s box-shadow;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  /**/

*{
    scroll-behavior: smooth;
    transition: 0.2s ease;
}

.forPhone{
    display: none;
}

.forPC{
    display: flex;
}

body{
    color: #150937;
}

.navbar img{
    height: 14rem;
    margin-left: 5rem;
    margin-top: 10px;
}

.navbarList{
    font-size: 16px;
    display: flex;
    margin-right: 5rem;
    margin-top: 0rem;   
}

.navList{
    margin: 0rem 20px;
    position: relative;
}

.updated-date img{
    height: 4rem;
    margin-left: 38rem;
    margin-right: 2rem;
}


.checkbtn{
    font-size: 30px;
}

.dropdown-toggle::after{
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.dropdown-card{
    font-size: 15px;
    font-weight: 300;
    background-color: white;
    position: absolute;
    top: 23px;
    width: 18rem;
    padding: 15px 10px;
    border: 2px solid snow;
    border-radius: 7%;
    display: none;
}

.serviceIcon{
    padding-right: 7px;
    visibility: hidden;
}

.dropdown-card>ul>li:hover .serviceIcon{
    visibility: visible;
}

.navList:hover .dropdown-card{
    display: block;
}

.dropdown-card li:hover{
    color: #B22FF0;
}

.loginSignup{
    color: #B22FF0;;
    border: 1px solid #B22FF0;
    border-radius: 25px 25px;
    padding: 5px 15px;
    margin-top: -2px;
}

.loginSignup span{
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.1;
    margin-top: 0px;
}

.loginSignup:hover{
    background-color: #B22FF0;
    color: white;
}

.offerImg{
    margin: 3rem auto;
    border-radius: 20px;
}

.home li i{
    color: #B22FF0;
}

.homeBody{
    display: flex;
    padding: 0 11rem;
}

.homeButton{
    font-size: 16px;
    font-weight: 500;
    border-radius: 25px;
    padding: 10px 25px;
    text-align: center; 
}

.homeButton1{
    background-color: #B22FF0;
    color: white;
    transition: color .15s;
    border: 1px solid white;
}

.homeButton1:hover{
    background-color: white;
    color: #B22FF0;
    border: 1px solid #B22FF0;
}

.homeButton1 i{
    margin-left: 10px;
    background-color: white;
    color: #B22FF0;
}

.homeButton1:hover i{
    background-color: #B22FF0;
    color: white;
}

.homeButton2{
    color: #B22FF0;
    margin-left: 10px;
    border: 1px solid #B22FF0;
}

.homeImage img{
    height: 400px;
    width: 500px;
    margin-right: 12rem;
}

.what{
    background-image: url("../images/what_background.png");
    background-size: 100% 400px;
}

.complete{
    background-image: url("../images/complete_background.png");
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 100% 500px;
}

.complete img{
    position: relative;
    top: 50px;
    margin: 0 auto;
}

.used-card{
    display: flex;
    background-color: #f7f7fc;
    margin: 0 auto;
    font-size: 30px;
    width: 83rem;
    height: 17rem;
    padding: 2rem 1.5rem;
}

.used-left{
    padding: 10px 20px;
}

.used-head{
    font-size: 32px;
    font-weight: 500;
    background-color: #150937;
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
    display: inline;
}

.used-right{
    font-size: 20px;
    padding: 10px 5rem;
    font-weight: 600;
}

.used-right li i{
    font-size: 30px;
}

.used-sales{
    font-size: 14px;
}

.product-list-text{
    width: 50%;
    padding-top: 4rem;
}

.amazing img{
    margin: 4rem auto;
}

.demo-body-input{
    width: 100%;
}

.emailBodyTop{
    width: 50%;
    padding: 5rem 0 5rem 11rem;
}


.messageCharge{
    margin-top: 5rem;
}

.messageChargeTablePC{
    display: flex;
    margin: 2.5rem 1rem 0 1rem;
}

.messageChargeTablePC tbody tr:nth-child(even){
    background-color: #eff0f7;
}

.messageChargeTablePC tbody tr:nth-child(odd){
    background-color: #f7f7fc;
}

.messageChargeTablePC table .tableLeft{
    border-right: 5px solid white;
}

.messageChargeTablePC tbody td{
    font-size: 14px;
}

.experience-body{
    display: flex;
    padding: 1.5rem;
    max-width: 64rem;
}

.experience-number{
    border-left: 1px solid rgba(0,0,0,.2);
    width: 50%;
}

.experience-radio{
    width: 50%;
    padding: 2rem;
}


.faqHead{
    background-color: #eff0f6;
    padding: 10px 30px;
}

.faqPanel{
    background-color: #eff0f6;
    margin-top: 10px;
    padding: 10px 30px;
}

.faqDiv{
    border-radius: 100px;
}

.let{
    background-color: #B22FF0;
}

.letButton2{
    font-size: 16px;
    font-weight: 500;
    border: 1px solid white;
    border-radius: 25px;
    padding: 10px 25px;
    background-color: white;
    color: #B22FF0;
}

.letButton2:hover{
    color: white;
    background-color: #B22FF0;
    transition: color .15s;
}

.alwaysBody{
    display: flex;
}

.alwaysBodyHead{
    margin: auto auto;
}

.alwaysBodyMain{
    margin: 0rem auto;
}

.alwaysBodyCard{
    width: 100%;
    padding: 1.5rem 3rem 1.5rem 1.5rem;
}

.slick-dots li.slick-active button:before{
    margin-top: 30px;
    background-color: #B22FF0;
    content: "";
    font-size: 15px;
    height: 15px;
    width: 30px;
}

.slick-dots li button:before{
    margin-top: 30px;
    color: #B22FF0 !important;
    font-size: 10px !important;
    width: 10px;
    height: 15px;
    border-radius: 10px;
    opacity: 1.25 !important;
}







.child {
    position: absolute;
    display: none;
}

.parent:hover+.child {
    display: block;
    padding-top: 14px;
}

.child:hover {
    display: block;
    padding-top: 14px;
}

.mobile-btn {
    display: none;
}

@media (max-width: 500px) {
    .nav-details {
        display: none;
    }

    .mobile-btn {
        display: block;
    }
}


.mobile-child {
    position: absolute;
    display: none;
}

.mobile-parent:hover+.mobile-child {
    display: block;
    padding-top: 14px;
    left: 0%;
    position: absolute;
    background-color: white;
    /* padding-right: 10%; */
}

.mobile-child:hover {
    display: block;
    padding-top: 14px;
    left: 0%;
    position: absolute;
    background-color: white;
}